.footer{
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 28px;
	background-color: #795548;
	flex-wrap: wrap;
	z-index: 1000;
	padding-top: 25px;
}
.footer a{
	text-decoration: none;
	color: #EFEBE9;
	padding-left: 10px;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.sitemap{
	font-size: 15px;
	padding-bottom: 25px;
	/*height: 30px;*/

}
.footer.contact{
	font-size: 15px;
}
